@font-face {
  font-family: 'HelveticaNeue-Light';
  src: url("../assets/fonts/HelveticaNeue-Light.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/HelveticaNeue-Light.woff") format("woff"), url("../assets/fonts/HelveticaNeue-Light.ttf") format("truetype"), url("../assets/fonts/HelveticaNeue-Light.svg#HelveticaNeue-Light") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'HelveticaNeue-Bold';
  src: url("../assets/fonts/HelveticaNeue-Bold.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/HelveticaNeue-Bold.woff") format("woff"), url("../assets/fonts/HelveticaNeue-Bold.ttf") format("truetype"), url("../assets/fonts/HelveticaNeue-Bold.svg#HelveticaNeue-Bold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'HelveticaNeue-Medium';
  src: url("../assets/fonts/HelveticaNeue-Medium.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/HelveticaNeue-Medium.woff") format("woff"), url("../assets/fonts/HelveticaNeue-Medium.ttf") format("truetype"), url("../assets/fonts/HelveticaNeue-Medium.svg#HelveticaNeue-Medium") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'HelveticaNeue';
  src: url("../assets/fonts/HelveticaNeue.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/HelveticaNeue.woff") format("woff"), url("../assets/fonts/HelveticaNeue.ttf") format("truetype"), url("../assets/fonts/HelveticaNeue.svg#HelveticaNeue") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'HelveticaNeue-Thin';
  src: url("../assets/fonts/helveticaneue-thin-webfont.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/helveticaneue-thin-webfont.woff") format("woff"), url("../assets/fonts/helveticaneue-thin-webfont.ttf") format("truetype"), url("../assets/fonts/helveticaneue-thin-webfont.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

body {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  font-family: 'HelveticaNeue-Light', Arial, sans-serif;
  font-size: 1.6rem;
  color: #231F20;
  background: #F2F2F2; }

#admin,
#root,
.wrapper,
body,
html {
  height: 100%; }

html {
  overflow: hidden;
  font-size: 62.5%; }

#top {
  overflow: hidden;
  margin: 0 auto;
  min-height: 100%;
  height: 100%; }
  #top #push {
    height: 150px; }
  #top .row {
    background: #FFF;
    margin: 0 auto;
    float: none; }
  #top header {
    height: 100px;
    background: #FFF;
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4); }
    #top header [class*='col'] nav:last-child ul li:last-child a {
      background: #FC0; }
    #top header [class*='col'] img {
      display: block;
      width: 135px;
      height: 62px;
      margin-top: 20px; }
    #top header [class*='col'] ul {
      margin: 0 0 0 20px;
      padding: 0;
      list-style: none; }
      #top header [class*='col'] ul li {
        float: left;
        margin-left: 10px; }
    #top header [class*='col'] a {
      display: block;
      padding: 0 20px;
      height: 100px;
      line-height: 100px;
      color: #000; }

#body {
  margin-top: 75px; }

#bottom {
  height: 150px;
  border-top: 30px solid #FC0;
  background: #FFF; }
  #bottom footer ul {
    margin: 0;
    padding: 0;
    list-style: none; }
  #bottom footer a {
    color: #000; }

@media (max-width: 767px) {
  #body {
    margin-top: 52px; } }

li,
ul {
  margin: 0;
  padding: 0;
  list-style: none; }

a {
  text-decoration: none;
  color: #107BBF;
  cursor: pointer;
  outline: none; }
  a:active, a:focus, a:hover, a:visited {
    text-decoration: none;
    color: #107BBF;
    cursor: pointer;
    outline: none; }

p {
  margin: 0 0 20px; }

h1 {
  font-size: 2.5rem;
  font-family: 'HelveticaNeue-Light';
  margin-bottom: 30px;
  margin-top: 30px; }

h2 {
  font-size: 3.2rem;
  font-family: 'HelveticaNeue-Light'; }

h3 {
  font-size: 2rem; }

h4 {
  font-size: 1.6rem; }

p.heading-sub {
  margin-bottom: 47px;
  margin-top: -20px; }

.large-text {
  font-size: 3rem;
  font-family: "HelveticaNeue-Light"; }

.align-center {
  text-align: center; }

.align-right {
  text-align: right; }

.align-left {
  text-align: left; }

.margin-bottom-10 {
  margin-bottom: 10px; }

.margin-bottom-20 {
  margin-bottom: 20px; }

.margin-bottom-30 {
  margin-bottom: 30px; }

.margin-bottom-40 {
  margin-bottom: 40px; }

.margin-top-50 {
  margin-top: 50px; }

.margin-top-10 {
  margin-top: 10px; }

.margin-right-10 {
  margin-right: 10px; }

.margin-right-20 {
  margin-right: 20px; }

.margin-right-30 {
  margin-right: 30px; }

.padding-right-20 {
  padding-right: 20px; }

.opacity-4 {
  opacity: .4; }

.font-medium {
  font-family: "HelveticaNeue-Medium" !important; }

.font-bold {
  font-family: "HelveticaNeue-Bold" !important; }

.text-bold {
  font-family: "HelveticaNeue";
  font-weight: 500; }

.large-font {
  font-size: 2.4rem !important; }
  @media (max-width: 767px) {
    .large-font {
      font-size: 1.6rem !important; } }

.with-errors {
  display: none; }

.has-error input.form-control {
  border-color: #FF001F !important;
  border-width: 1px !important; }

.has-error .with-errors {
  display: block;
  color: #FF001F;
  clear: both; }

.has-error .input-group-addon {
  color: #555;
  background-color: #F2DEDE;
  border-color: #FF001F;
  border-width: 2px !important; }

.row-link,
.row-link label {
  cursor: pointer; }

.row-link:focus, .row-link:hover {
  border: none;
  outline-color: transparent; }

a.disabled {
  opacity: .38;
  cursor: not-allowed; }

.btn {
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  font-family: "HelveticaNeue-Light", sans-serif;
  height: 48px;
  font-size: 16px;
  color: #231F20;
  border: 1px solid #7A7A7A;
  line-height: 44px;
  min-width: 178px;
  padding: 0 20px;
  text-align: center;
  letter-spacing: .25px; }
  .btn:active {
    box-shadow: none; }
  .btn.active.focus, .btn.active:focus {
    outline: none;
    box-shadow: none; }
  .btn.focus, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 1px #5E9ED6; }

.box-header .btn {
  width: auto !important; }

.btn.btn-default {
  transition: box-shadow 0.35s cubic-bezier(0.39, 0.575, 0.565, 1);
  background-color: transparent; }
  .btn.btn-default:hover {
    box-shadow: none; }
  .btn.btn-default[disabled]:hover {
    box-shadow: none;
    border-color: #7A7A7A; }

.btn.btn-yellow {
  transition: background-color .3s ease-in-out;
  border-color: transparent;
  background-color: #FC0;
  color: #231F20;
  font-size: 1.6rem;
  height: 48px;
  line-height: 46px; }
  .btn.btn-yellow:hover {
    background-color: #FFD633; }
  .btn.btn-yellow[disabled] {
    background-color: #D2D2D2;
    color: #899197; }

.buttons.buttons-switch {
  overflow: hidden;
  width: 450px !important; }
  @media (max-width: 767px) {
    .buttons.buttons-switch {
      width: 100% !important; } }
  .buttons.buttons-switch .btn.btn-yellow {
    float: right; }
  .buttons.buttons-switch .btn.btn-default {
    float: left;
    margin-left: 0 !important; }
  @media (max-width: 767px) {
    .buttons.buttons-switch .btn {
      float: none !important; } }
  .buttons.buttons-switch .btn + .btn {
    margin-right: 30px !important; }
    @media (max-width: 767px) {
      .buttons.buttons-switch .btn + .btn {
        margin-right: 0 !important; } }
  .buttons.buttons-switch.btn250 {
    width: 510px !important; }
    .buttons.buttons-switch.btn250 .btn {
      width: 250px !important; }
    .buttons.buttons-switch.btn250 .btn + .btn {
      margin-right: 10px !important; }
    @media (max-width: 767px) {
      .buttons.buttons-switch.btn250 {
        width: 100% !important; }
        .buttons.buttons-switch.btn250 .btn {
          width: 210px !important; }
        .buttons.buttons-switch.btn250 .btn + .btn {
          margin-right: 0 !important; } }

@media screen and (max-width: 767px) {
  h1 {
    font-size: 1.6rem;
    margin-top: 14px;
    margin-bottom: 14px;
    padding-left: 0;
    margin: 20px 0; }
  h3 {
    font-size: 1.4rem; }
  h4 {
    font-size: 1.3rem; }
  .large-text {
    font-size: 2rem; }
  .btn,
  .btn.btn-yellow {
    font-size: 1.4rem;
    margin-bottom: 20px;
    min-width: 130px;
    width: 178px;
    height: 40px;
    line-height: 38px; }
  .btn-group .btn {
    margin-bottom: 0; }
  .box-header .btn {
    margin-bottom: 0; }
  p.with-errors {
    text-align: center; }
  p.heading-sub {
    font-size: 1.2rem;
    margin-bottom: 20px; }
  .margin-bottom-30-mobile {
    margin-bottom: 30px; } }

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0); }
  20%,
  80% {
    transform: translate3d(2px, 0, 0); }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0); }
  40%,
  60% {
    transform: translate3d(4px, 0, 0); } }

.no-padding {
  padding: 0; }

.debug-border {
  border: 1px solid #000; }

.display-table {
  display: table; }

.no-right-border {
  border-right: 0 !important; }

.no-bottom-border {
  border-bottom: 0 !important; }

.bottom-divider {
  border-bottom: 1px solid #D3D2D2; }

.vertical-centered {
  display: table-cell;
  vertical-align: middle; }

.ib-page-header {
  font-size: 40px;
  display: block; }

.error-text {
  color: #FF001F; }

.dropdown-menu {
  border-radius: 0;
  box-shadow: none;
  border: 1px solid #7A7A7A; }

.form-control {
  background-clip: padding-box;
  box-shadow: none; }

form {
  width: auto;
  margin: auto; }
  form label {
    font-weight: normal; }
  form .form-control {
    border: 1px solid #7A7A7A;
    width: 100%;
    padding-left: 1.2em;
    padding-right: 1.2em;
    border-radius: 0;
    color: #A3A1A1;
    height: 35px;
    font-size: 1.4rem; }
    form .form-control:focus {
      box-shadow: none; }
    @media screen and (min-width: 768px) {
      form .form-control {
        height: 48px;
        font-size: 1.6rem; } }
  form .input-group-amount {
    position: relative; }
    form .input-group-amount__currency {
      position: absolute;
      left: 10px;
      top: 1px;
      bottom: 1px;
      width: 30px;
      text-align: center; }
      form .input-group-amount__currency i {
        font-style: normal;
        display: inline-block;
        line-height: 46px;
        vertical-align: middle; }
        @media (max-width: 767px) {
          form .input-group-amount__currency i {
            line-height: 33px; } }
    form .input-group-amount .form-control {
      padding-left: 10px;
      color: #231F20; }
      @media screen and (min-width: 768px) {
        form .input-group-amount .form-control {
          padding-left: 10px; } }
    form .input-group-amount .unit-amount {
      padding-left: 38px;
      font-size: 36px;
      height: 72px;
      line-height: 72px; }
  form .btn.select-dropdown {
    width: 100%;
    height: 48px;
    border-radius: 0;
    text-align: left; }
    @media screen and (max-width: 767px) {
      form .btn.select-dropdown {
        line-height: 33px;
        height: 35px;
        font-size: 1.4rem; } }
    form .btn.select-dropdown .caret {
      right: .75em;
      left: auto; }
  form .form-group.has-error .input-group {
    border-color: #FF001F !important; }
  form .form-group.has-error .form-control {
    border-color: #FF001F; }
  @media screen and (min-width: 768px) {
    form .form-group {
      margin-bottom: 30px; } }
  @media screen and (min-width: 768px) {
    form .form-group-list .form-group:last-child {
      margin-bottom: 0; } }

.label-red {
  color: #FF001F; }

.label-yellow {
  color: #FC0; }

.label-green {
  color: #090; }

.label-gray {
  color: #A8A8A8; }

.label-black {
  color: #231F20; }

.pad-right-0 {
  padding-right: 0; }

input[type='text']::-ms-clear {
  display: none; }

input.form-control::-ms-clear {
  display: none; }

.loading .fa-spin,
.spinner .fa-spin {
  font-size: 24px; }

form .form-control {
  color: #231F20; }

.not-allowed {
  cursor: not-allowed; }

.pre-line {
  white-space: pre-line; }

.entitlement-grey-out-link {
  color: #899197 !important;
  cursor: default !important; }

.entitlement-cursor-default {
  cursor: default !important; }

.entitlement-grey-out {
  background-color: #D3D2D2 !important;
  color: #899197;
  cursor: default !important; }

.entitlement-greyish-link {
  color: #E3E3E3 !important;
  cursor: default !important; }

.gray-out-profile {
  border-left: 0 !important;
  font-family: "HelveticaNeue-Light" !important;
  cursor: default !important; }

.disable-lists .arrow-right-icon {
  color: #D3D2D2 !important; }

.entitlement-grey-out-image {
  -webkit-filter: grayscale(100%) !important;
  filter: grayscale(100%) !important;
  border-radius: 50% !important; }

.entitlement-grey-out-icon {
  -webkit-filter: opacity(30%) !important;
  filter: opacity(30%) !important; }

.box {
  background: #FFF;
  border: 1px solid #D1D1D1;
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 18px; }
  .box .box-header {
    border-bottom: #D1D1D1 solid 1px;
    padding: 12px 15px;
    overflow: hidden;
    display: table;
    width: 100%; }
    .box .box-header > div,
    .box .box-header h3 {
      display: block;
      vertical-align: middle;
      font-size: 1.6rem;
      margin: 0; }
      .box .box-header > div.right-link a,
      .box .box-header h3.right-link a {
        color: #231F20;
        font-family: "HelveticaNeue-Light";
        font-size: 1.6rem; }
        .box .box-header > div.right-link a img,
        .box .box-header h3.right-link a img {
          margin-right: 0;
          margin-left: 10px; }
      .box .box-header > div img,
      .box .box-header h3 img {
        margin-right: 10px; }
  .box .box-content h4 {
    font-weight: bold; }
  .box .box-content.table {
    display: table;
    margin-bottom: 0; }
  .box .box-content .panel-default {
    border-color: transparent;
    margin-bottom: 0; }
    .box .box-content .panel-default .panel-heading {
      background: none;
      padding: 0;
      border-width: 0; }
      .box .box-content .panel-default .panel-heading a {
        display: table;
        width: 100%;
        color: #231F20; }
    .box .box-content .panel-default .panel-body {
      padding: 0;
      display: table;
      width: 100%; }
      .box .box-content .panel-default .panel-body .border-bottom:last-child li {
        border-width: 0; }
    .box .box-content .panel-default .panel-collapse {
      border-top: #DDD solid 1px; }

.box-buttons.list-group {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0; }
  @media screen and (min-width: 768px) {
    .box-buttons.list-group {
      padding-left: 5px;
      padding-right: 15px; } }

.box-buttons .list-group__business,
.box-buttons .list-group__manage,
.box-buttons .list-group__transact {
  display: block;
  height: 56px;
  background: #FFF;
  border: 1px solid #D1D1D1;
  border-bottom: none;
  font-weight: bold;
  padding: 15px; }
  .box-buttons .list-group__business:hover,
  .box-buttons .list-group__manage:hover,
  .box-buttons .list-group__transact:hover {
    cursor: default; }

.box-buttons .list-item-divider {
  margin: 16px;
  display: block; }

.box-buttons .list-group__manage {
  margin-top: 16px; }

.box-buttons .list-group-item {
  display: flex;
  align-items: center;
  padding: 12px;
  color: #231F20;
  font-size: 1.4rem;
  border: 1px solid #D1D1D1; }
  .box-buttons .list-group-item svg, .box-buttons .list-group-item img {
    width: 32px;
    height: 32px;
    margin-right: 12px; }
  .box-buttons .list-group-item:focus {
    outline: none; }

.banner {
  color: #FFF;
  font-size: 3rem;
  line-height: 35px;
  border-radius: 3px;
  background-size: cover;
  position: relative; }
  .banner div {
    position: absolute;
    bottom: 15px;
    left: 15px; }
    .banner div span {
      color: #FC0; }
  .banner.top-banner {
    width: 100%;
    height: 220px;
    margin-top: 25px;
    margin-bottom: 20px;
    background-image: url("../assets/images/top-banner.jpg");
    background-position: center;
    padding-left: 18px;
    padding-top: 135px; }
  .banner.right-banner {
    padding: 0 20px 20px;
    background-image: url("../assets/images/home-banner.jpg");
    height: 260px; }

.end-of-list .list-not-available-title {
  font-family: "HelveticaNeue-Bold"; }

.end-of-list .list-not-available-cellphone {
  margin-left: 5px;
  color: #4A90E2; }

.list-not-available-center {
  text-align: center;
  margin-top: 10px; }

.entit-grey-out-vas {
  background-color: #D3D2D2 !important;
  color: #899197;
  cursor: default !important;
  border: 0; }

.yellow-checkbox input[type='checkbox'], .yellow-checkbox input[type='radio'] {
  display: none; }

.yellow-checkbox label {
  padding-left: 30px; }

.yellow-checkbox label p {
  display: inline-block;
  margin: 0;
  padding: 3px 0; }

.yellow-checkbox label input + p::after {
  content: '';
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 0;
  top: 5px;
  background-repeat: no-repeat; }

.yellow-checkbox label input[type='radio'] + p::after {
  background-image: url("../assets/images/radio_empty.svg"); }

.yellow-checkbox label input[type='radio']:checked + p::after {
  background-image: url("../assets/images/radio_checked.svg"); }

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .loading img {
    animation: rotation .6s linear infinite; } }

@supports (-ms-ime-align: auto) {
  .loading img {
    animation: rotation .6s linear infinite; } }

@keyframes rotation {
  from {
    transform: rotate(0); }
  to {
    transform: rotate(360deg); } }

@media screen and (max-width: 767px) {
  .navbar-fixed-top {
    border-width: 0; }
  .box .box-header > div,
  .box .box-header h3 {
    height: 22px; }
    .box .box-header > div.right-link a,
    .box .box-header h3.right-link a {
      font-size: 1.4rem; }
      .box .box-header > div.right-link a img,
      .box .box-header h3.right-link a img {
        width: 24px; }
  .box .box-header h3 {
    padding-left: 7px; }
  .box .box-header img {
    width: 30px; }
  .box .box-content {
    font-size: 1.4rem; }
    .box .box-content .detail {
      padding: 10px 15px 10px 20px !important; }
  .banner.top-banner {
    height: 110px;
    font-size: 1.8rem;
    line-height: 25px;
    margin-top: -1px;
    width: auto;
    margin-left: -15px;
    margin-right: -15px;
    border-radius: 0; }
  .banner.right-banner {
    height: 115px;
    margin-bottom: 0;
    font-size: 1.4rem; } }

.flex {
  display: flex; }

.nav-tabs > li > a {
  margin-right: 0px;
  border: 1px solid #D1D1D1; }

#header {
  height: 72px;
  line-height: 72px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  background: #FFF; }
  #header #logo,
  #header #logoMobile {
    float: left; }
    #header #logo img,
    #header #logoMobile img {
      vertical-align: middle; }
  #header #logoMobile {
    font-size: 2.5rem;
    margin-right: 20px; }
  #header #menu {
    float: left; }
    #header #menu li {
      display: inline-block;
      padding: 0 5px;
      font-size: 1.8rem; }
      #header #menu li a {
        display: inline-block;
        height: 72px;
        color: #231F20; }
        #header #menu li a:hover {
          border-bottom: #FC0 solid 3px; }
  #header .glyphicon.glyphicon-menu-hamburger {
    color: #A9A9A9; }
  #header #rightMenu {
    float: right;
    font-size: 1.4rem; }
    #header #rightMenu a {
      padding: 0 15px;
      display: inline-block;
      color: #231F20; }
      #header #rightMenu a:hover {
        background: #FC0; }

svg {
  vertical-align: middle; }

.profile-icon {
  width: 32px;
  height: 32px; }
  @media screen and (max-width: 420px) {
    .profile-icon {
      margin-right: 0;
      margin-top: 10px; } }

.contact-us-icon,
.logged-icon,
.home-icon {
  width: 32px;
  height: 32px; }
  @media screen and (max-width: 420px) {
    .contact-us-icon,
    .logged-icon,
    .home-icon {
      height: 24px;
      width: 24px;
      margin-right: 10px; } }

.nav.navbar-nav li > a {
  font-size: 1.4rem !important;
  font-weight: bold;
  line-height: 35px;
  display: flex;
  align-items: center; }

.navbar-left {
  float: left;
  margin-left: 15px; }

.navbar-default {
  background-color: #231F20; }
  .navbar-default .navbar-brand {
    padding: 0;
    margin-left: 0 !important; }
    .navbar-default .navbar-brand a {
      height: 70px;
      line-height: 70px;
      padding-right: 20px;
      margin-right: 20px;
      display: inline-block; }
      .navbar-default .navbar-brand a.border-right {
        border-right: solid 1px #4F4C4D; }
    .navbar-default .navbar-brand .home-icon {
      height: 24px;
      width: 22px; }
    .navbar-default .navbar-brand .logo svg {
      width: 170px;
      height: 44px; }
      @media screen and (max-width: 420px) {
        .navbar-default .navbar-brand .logo svg {
          height: 28px;
          margin-top: 12px; } }
  .navbar-default .navbar-toggle {
    border: none;
    padding-left: 0; }
    .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
      background: none; }
    .navbar-default .navbar-toggle .icon-bar {
      height: 2.25px;
      background: #FC0;
      width: 20.25px; }
      .navbar-default .navbar-toggle .icon-bar + .icon-bar {
        margin-top: 5.5px; }
      .navbar-default .navbar-toggle .icon-bar:nth-child(3) {
        width: 15px; }
  .navbar-default .navbar-nav > li.dropdown a {
    line-height: 30px; }
  .navbar-default .navbar-nav > li.dropdown.open > a {
    background: none;
    color: #FFF; }
    .navbar-default .navbar-nav > li.dropdown.open > a:focus, .navbar-default .navbar-nav > li.dropdown.open > a:hover {
      background: none;
      color: #FFF; }
  .navbar-default .navbar-nav > li.dropdown.open ul.dropdown-menu {
    border: none;
    padding: 0;
    background-color: #231F20; }
    .navbar-default .navbar-nav > li.dropdown.open ul.dropdown-menu li a {
      font-size: 1.6rem;
      border-top: #4F4C4D solid 1px;
      padding-right: 50px; }
      .navbar-default .navbar-nav > li.dropdown.open ul.dropdown-menu li a img {
        margin-right: 10px;
        display: inline-block;
        margin-top: -3px; }
      .navbar-default .navbar-nav > li.dropdown.open ul.dropdown-menu li a:hover {
        background: #393536; }
    .navbar-default .navbar-nav > li.dropdown.open ul.dropdown-menu li.active a {
      background: #393536; }
  .navbar-default .navbar-nav > li a {
    padding: 20px;
    font-size: 1.8rem;
    color: #FFF;
    height: 72px; }
    .navbar-default .navbar-nav > li a:focus, .navbar-default .navbar-nav > li a:hover {
      background: none;
      color: #FFF; }
  .navbar-default .navbar-nav > li.active > a {
    background: none;
    color: #FFF;
    border-bottom: #FC0 solid 3px; }
    .navbar-default .navbar-nav > li.active > a:focus, .navbar-default .navbar-nav > li.active > a:hover {
      background: none;
      color: #FFF;
      border-bottom: #FC0 solid 3px; }
  .navbar-default ul.navbar-right {
    margin-right: 0 !important; }
    .navbar-default ul.navbar-right > li {
      border-left: #4F4C4D solid 1px; }
      .navbar-default ul.navbar-right > li:last-child a {
        padding-right: 0; }
      .navbar-default ul.navbar-right > li.active {
        border-bottom: none; }

@media (max-width: 767px) {
  .navbar-nav {
    margin: 0 -10px; }
  .navbar-default .navbar-brand {
    padding-left: 0;
    margin-top: 0;
    display: block;
    width: calc(100% - 125px);
    text-align: center; }
    .navbar-default .navbar-brand a {
      height: 50px;
      line-height: 30px;
      padding-right: 0;
      padding-left: 10px; }
      .navbar-default .navbar-brand a.mobile-logout {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 100;
        border-left: solid 0.5px #FC0;
        border-right: none;
        padding-left: 20px;
        padding-right: 0; }
      .navbar-default .navbar-brand a.logo img {
        margin-top: 12px;
        height: 28px; }
    .navbar-default .navbar-brand img {
      margin-top: 10px; }
  .navbar-toggle {
    margin-right: 7px; }
  #sideBarModal {
    text-align: left;
    width: 75%; }
    #sideBarModal::before {
      display: none; }
    #sideBarModal .modal-dialog .modal-content .modal-header button.close {
      left: 12px;
      color: transparent;
      font-size: 0;
      background: url("../assets/images/close-yellow.svg") no-repeat left top;
      height: 32px;
      width: 32px;
      opacity: 1; }
    #sideBarModal .modal-dialog .modal-content .modal-header button img {
      width: 32px; }
    #sideBarModal .modal-dialog .modal-content .modal-body .navbar-nav li .dropdown-toggle {
      display: none; }
    #sideBarModal .modal-dialog .modal-content .modal-body .navbar-nav li .dropdown-menu {
      display: block;
      background: none;
      border: none;
      position: static;
      width: 100%;
      padding: 0; }
    #sideBarModal .modal-dialog .modal-content .modal-body .navbar-nav li svg {
      height: 24px;
      width: 24px;
      margin-right: 10px;
      margin-top: 0; }
    #sideBarModal .modal-dialog .modal-content .modal-body .navbar-nav li a {
      border-left: #231F20 solid 3px;
      font-size: 1.2rem; }
    #sideBarModal .modal-dialog .modal-content .modal-body .navbar-nav li.active a {
      border-left: #FC0 solid 3px; }
    #sideBarModal .modal-dialog .modal-content .modal-body .navbar-right {
      margin-top: 144px;
      border-top: 0.5px solid #393536;
      margin-bottom: 42px; } }

@media (min-width: 768px) {
  .navbar-default .container {
    width: 100%;
    padding: 0 20px; }
  .navbar-nav:first-child > li:first-child {
    display: none; }
  .navbar-default .navbar-nav.navbar-right li a {
    font-size: 1.4rem; } }

.menu-sidebar.modal {
  max-width: 100%;
  background-color: #231F20; }
  .menu-sidebar.modal.fade {
    transition: transform .3s ease-out, opacity .15s linear; }
    .menu-sidebar.modal.fade .modal-dialog {
      transition: none; }
    .menu-sidebar.modal.fade:not(.in).left {
      transform: translate3d(-100%, 0, 0); }
    .menu-sidebar.modal.fade:not(.in).right {
      transform: translate3d(100%, 0, 0); }
    .menu-sidebar.modal.fade:not(.in).left .modal-dialog {
      transform: translate3d(-100%, 0, 0); }
    .menu-sidebar.modal.fade:not(.in).right .modal-dialog {
      transform: translate3d(100%, 0, 0); }
  .menu-sidebar.modal.left {
    right: auto; }
  .menu-sidebar.modal.right {
    left: auto; }
  .menu-sidebar.modal .modal-dialog {
    margin: 0;
    width: auto; }
    .menu-sidebar.modal .modal-dialog .modal-header {
      border-bottom-width: 0;
      height: 38px; }
      .menu-sidebar.modal .modal-dialog .modal-header .hello-message {
        margin: 0; }
    .menu-sidebar.modal .modal-dialog .modal-content {
      border: 0;
      box-shadow: none;
      background: none; }
      .menu-sidebar.modal .modal-dialog .modal-content .modal-body {
        padding: 0 10px 20px; }
        .menu-sidebar.modal .modal-dialog .modal-content .modal-body ul li {
          border-bottom-width: 2px; }
          .menu-sidebar.modal .modal-dialog .modal-content .modal-body ul li.active a {
            background-color: #393536;
            border-left: #FC0 3px solid; }
          .menu-sidebar.modal .modal-dialog .modal-content .modal-body ul li a {
            padding: 8px 15px;
            color: #FFF;
            border-bottom: 0.5px solid #393536; }
            .menu-sidebar.modal .modal-dialog .modal-content .modal-body ul li a:hover {
              background-color: #393536; }
          .menu-sidebar.modal .modal-dialog .modal-content .modal-body ul li:first-child a {
            border-top: 0.5px solid #393536; }
        .menu-sidebar.modal .modal-dialog .modal-content .modal-body .logo-side-bar {
          margin-left: 10px; }
          .menu-sidebar.modal .modal-dialog .modal-content .modal-body .logo-side-bar svg {
            width: 140px;
            height: 36px; }

#footer {
  font-size: 1.2rem;
  font-family: "HelveticaNeue";
  color: #6A6862;
  padding-bottom: 20px;
  overflow: hidden;
  background: #FFF; }
  #footer .yellow-line {
    background-color: #FC0;
    height: 50px;
    margin-bottom: 30px; }
  #footer a {
    color: #6A6862;
    padding-right: 30px; }
  #footer .left-links {
    float: left; }
  #footer .right-links {
    float: right; }

@media screen and (max-width: 767px) {
  #footer .yellow-line {
    height: 20px; }
  #footer .left-links {
    margin-bottom: 10px; }
  #footer .right-links {
    float: left; } }

.ib-container-fluid {
  position: relative;
  overflow: hidden; }

.page-footer {
  background-color: #FFF;
  border-top: 10px solid #FC0;
  font-size: 1.6rem;
  color: #231F20;
  padding-top: 20px;
  margin-top: 20px;
  padding-bottom: 20px; }
  @media screen and (min-width: 768px) {
    .page-footer {
      margin-top: 90px; } }
  .page-footer .lock-icon {
    max-height: 48px;
    margin-left: 1.5em;
    display: inline-block;
    width: 36px; }
  .page-footer .text {
    display: inline-block;
    margin-left: 1em; }
  .page-footer .media {
    margin-top: .5em; }
  .page-footer .hr-footer {
    background-color: #D3D2D2;
    height: 1px; }
  .page-footer .footer-links {
    font-size: 1.2rem;
    margin-left: 1em; }
    .page-footer .footer-links a {
      color: #6A6862; }
      .page-footer .footer-links a:hover {
        opacity: .7; }
    .page-footer .footer-links li {
      display: inline-block; }
      .page-footer .footer-links li + li {
        margin-left: 2em; }
  .page-footer .copyright {
    font-size: 1.2rem;
    margin-bottom: 1.5em;
    margin-right: 1em;
    text-align: right;
    color: #6A6862; }
  .page-footer .logo-footer {
    text-align: right;
    padding-right: 1em;
    padding-top: 10px; }
    .page-footer .logo-footer .logo-icon {
      width: 182px;
      height: 45px; }
  @media screen and (max-width: 767px) {
    .page-footer {
      border-top-width: 6px;
      padding-top: 15px;
      padding-bottom: 10px; }
      .page-footer .text {
        margin-left: 0;
        line-height: 1.5; }
      .page-footer .media {
        margin-top: 0; }
      .page-footer .logo-footer {
        padding-right: 0;
        padding-top: 0; }
        .page-footer .logo-footer .logo-icon {
          width: 101px;
          height: 25px; }
      .page-footer .copyright,
      .page-footer .footer-links,
      .page-footer .text {
        font-size: 8px; }
      .page-footer .hr-footer {
        margin-top: 10px;
        margin-bottom: 10px; }
      .page-footer .footer-links {
        margin-left: 0;
        margin-bottom: 1em; }
        .page-footer .footer-links a {
          color: #ACACAC; }
      .page-footer .copyright {
        text-align: left;
        margin-bottom: 1.2em;
        color: #ACACAC; } }

.expansion-panel {
  margin: 0 -20px; }
  .expansion-panel--hide {
    background-color: #F7F7F7; }
  .expansion-panel--show {
    background-color: #F7F7F7; }
  .expansion-panel__more-info {
    padding: 15px 20px; }
    @media screen and (min-width: 768px) {
      .expansion-panel__more-info {
        padding: 20px 25px; } }
  .expansion-panel__more-info-text {
    display: inline; }
    @media screen and (min-width: 768px) {
      .expansion-panel__more-info-text {
        display: inline-block;
        margin-top: 4px; } }
  .expansion-panel__more-info-collapse {
    margin-top: 20px;
    font-size: 12px;
    font-family: "HelveticaNeue-Light"; }
    .expansion-panel__more-info-collapse p {
      margin-bottom: 20px; }
  .expansion-panel__more-info-title {
    display: flex;
    justify-content: space-between;
    line-height: 20px;
    align-items: center;
    font-size: 14px; }
    @media screen and (min-width: 768px) {
      .expansion-panel__more-info-title {
        font-size: 16px; } }
    .expansion-panel__more-info-title img {
      width: 28px;
      height: 28px;
      margin-right: 15px;
      float: left;
      font-size: 16px;
      line-height: 24px; }
  .expansion-panel__more-info-toggle {
    flex: 1 1 auto;
    display: flex;
    justify-content: space-between; }

.breadscrumb {
  margin-top: -15px;
  margin-bottom: 20px;
  font-size: 14px; }
  .breadscrumb a {
    color: #231F20;
    text-decoration: underline; }
  .breadscrumb img {
    margin: 0 5px;
    width: 6px; }
  .breadscrumb span {
    font-weight: bold; }

.loginPage .bg-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  margin: 0;
  padding: 0;
  background: rgba(0, 0, 0, 0.0005); }
  @media screen and (min-width: 768px) {
    .loginPage .bg-overlay {
      background: rgba(0, 0, 0, 0.5); } }

.loginPage .wrapper {
  position: relative;
  color: #FFF;
  overflow: hidden;
  min-height: 100vh;
  padding-bottom: 110px; }

.loginBox .loginPage__text-new-customer {
  display: block;
  font-size: 14px;
  margin-top: 30px;
  color: #FC0;
  text-decoration: underline; }
  .loginBox .loginPage__text-new-customer:hover {
    color: #FC0;
    opacity: 1; }
  @media screen and (min-width: 768px) {
    .loginBox .loginPage__text-new-customer {
      margin-top: 40px;
      font-size: 16px; } }

.loginPage .wrapper .header {
  padding-top: 40px;
  padding-bottom: 45px;
  margin-bottom: 25px;
  margin-top: 50px; }
  @media screen and (min-width: 768px) {
    .loginPage .wrapper .header {
      margin-bottom: 70px; } }

.loginBox {
  overflow: hidden;
  font-family: 'HelveticaNeue-Thin', sans-serif; }
  .loginBox a {
    color: #FC0; }
    .loginBox a:hover {
      opacity: .8; }
  .loginBox .control-label {
    font-weight: normal; }
  .loginBox .buttons {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px; }
  .loginBox .btn {
    max-width: 270px;
    margin: 0 auto;
    box-shadow: none;
    outline: none;
    font-size: 1.6rem;
    margin-bottom: 30px;
    margin-top: 30px; }
    @media screen and (min-width: 768px) {
      .loginBox .btn {
        margin-top: 0; } }
    .loginBox .btn.btn-default {
      color: #FFF;
      background-color: transparent;
      border-color: #FFF; }
  .loginBox .link-sign-up {
    display: block;
    font-size: 14px;
    margin: 0 auto;
    max-width: 380px;
    margin-top: 30px; }
    @media screen and (min-width: 768px) {
      .loginBox .link-sign-up {
        font-size: 1.6rem;
        margin-top: 40px; } }
  .loginBox .form-control {
    border-radius: 0;
    color: #231F20;
    height: 48px;
    font-size: 18px;
    box-shadow: none;
    border-color: #7A7A7A; }
    .loginBox .form-control:-ms-input-placeholder {
      color: #999; }
  .loginBox .form-group {
    max-width: 330px;
    margin: auto;
    min-height: 130px; }
    .loginBox .form-group.has-error .input-feedback {
      color: #FF001F;
      font-size: 16px;
      margin-top: .5em; }
    .loginBox .form-group.has-error .control-label {
      color: #FFF; }
    .loginBox .form-group .reset-password {
      font-size: 16px;
      margin-top: .5em;
      text-align: right; }
  .loginBox .login-box-container {
    max-width: 930px; }
  .loginBox .login-errors-line {
    text-align: center;
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0;
    min-height: 25px; }
    @media screen and (min-width: 992px) {
      .loginBox .login-errors-line {
        padding: 0 15px;
        max-width: 100%; } }
  @media screen and (max-width: 767px) {
    .loginBox {
      padding-left: 15px;
      padding-right: 15px;
      min-height: 85vmin; }
      .loginBox .control-label {
        font-size: 14px; }
      .loginBox .form-group {
        margin-bottom: 5px;
        min-height: 95px; }
        .loginBox .form-group.has-error .input-feedback {
          font-size: 14px; }
        .loginBox .form-group .reset-password {
          font-size: 14px; }
      .loginBox .form-control {
        height: 35px;
        font-size: 18px;
        text-align: left; }
      .loginBox .buttons {
        margin-top: 0;
        margin-bottom: 0; }
      .loginBox .btn {
        width: 100%;
        font-size: 14px; }
        .loginBox .btn + .btn {
          margin-top: 20px; }
      .loginBox .login-box-container {
        max-width: none;
        padding-bottom: 0; }
        .loginBox .login-box-container [class*='col-'] {
          padding-left: 0;
          padding-right: 0; }
      .loginBox .login-errors-line {
        font-size: 14px;
        min-height: 0;
        margin-top: 5px; } }

.mainContent {
  margin-bottom: 25px; }
  @media screen and (max-width: 1024px) {
    .mainContent {
      min-height: 85vmin;
      margin-bottom: 20px; } }
  @media screen and (min-width: 992px) {
    .mainContent {
      margin-bottom: 20px; } }

.text-about-cba,
.text-contact-us {
  text-align: center;
  font-family: 'HelveticaNeue-Thin', sans-serif;
  color: #FFF; }

.text-about-cba {
  width: 100%;
  font-size: 8px;
  margin-bottom: -40px; }
  @media screen and (min-width: 768px) {
    .text-about-cba {
      font-size: 12px;
      margin-bottom: 25px; } }
  @media screen and (min-width: 992px) {
    .text-about-cba {
      font-size: 12px;
      margin-bottom: 5px; } }
  .text-about-cba .about-bold {
    font-family: "HelveticaNeue-Bold"; }

.text-contact-us {
  font-size: 1.4rem;
  margin-top: 30px;
  display: block; }
  .text-contact-us a {
    color: #FC0; }
  @media screen and (min-width: 768px) {
    .text-contact-us {
      margin-top: 200px;
      font-size: 1.6rem; } }

.modal-login-require__notice {
  font-family: "HelveticaNeue-Bold"; }

.logout-container .box {
  padding: 2em 0; }

.logout-container .title {
  font-size: 1.2em;
  margin: 0 0 0; }

@media (max-width: 767px) {
  .logout-container {
    font-size: 1.4rem; } }

.alert.content {
  color: #000;
  font-size: 1.6rem;
  padding-left: 20px;
  margin: inherit;
  border-bottom: none; }

.alert strong {
  font-family: 'HelveticaNeue-Medium'; }

.alert-warning {
  background: #FFFBF0; }

.modal {
  text-align: center;
  padding-left: 0 !important; }
  .modal::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle; }

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  min-width: 280px;
  max-width: 420px;
  width: 100%;
  margin: 25px auto;
  padding: 0 20px;
  word-wrap: break-word; }
  @media screen and (min-width: 768px) {
    .modal-dialog {
      max-width: 100%;
      min-width: 420px;
      padding: 0 174px; } }
  @media screen and (min-width: 992px) {
    .modal-dialog {
      width: 620px;
      padding: 0; } }
  .modal-dialog .otp {
    margin-top: 10px; }
  .modal-dialog .with-errors {
    margin: 0;
    text-align: left;
    font-size: 1.4rem; }
  .modal-dialog .has-error .pin-number {
    border-color: #FF001F;
    border-width: 2px;
    box-shadow: none; }

.modal-content {
  border-radius: 3px;
  border: 0;
  display: block;
  height: auto;
  width: 100%;
  overflow: visible;
  position: relative; }
  .modal-content .close {
    color: #FC0;
    font-size: 2.5rem;
    font-weight: 500;
    position: absolute;
    right: 20px;
    top: 10px;
    opacity: 1;
    text-shadow: none;
    z-index: 1000;
    outline: none; }

.modal-header {
  background: #231F20;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: #FFF;
  display: block;
  padding: 20px;
  font-size: 1.8rem;
  text-align: center; }
  @media screen and (min-width: 992px) {
    .modal-header {
      display: flex;
      align-items: center;
      font-size: 3rem;
      padding: 25px 40px;
      text-align: left; } }
  .modal-header img {
    flex: 0 0 auto;
    object-fit: contain;
    height: 48px;
    width: 48px;
    margin: 0 auto 10px; }
    @media screen and (min-width: 992px) {
      .modal-header img {
        margin: 0 30px 0 0; } }
  .modal-header .img {
    height: 48px;
    width: 48px;
    display: block;
    margin: 0 auto 8px;
    background-size: contain;
    background-position: 50% 100%;
    background-repeat: no-repeat; }
    @media screen and (min-width: 768px) {
      .modal-header .img {
        align-self: center;
        background-position: 50% 50%;
        display: inline-block;
        vertical-align: middle;
        margin-right: 20px; } }
  .modal-header p {
    font-size: 1.6rem;
    margin-top: 20px; }

.modal-title {
  clear: both;
  display: block;
  font-size: 1.4rem;
  margin-bottom: 4px;
  text-align: center;
  width: 100%; }
  @media screen and (min-width: 768px) {
    .modal-title {
      align-self: center;
      font-size: 1.6rem; } }
  @media screen and (min-width: 992px) {
    .modal-title {
      text-align: left;
      display: inline;
      box-shadow: none;
      font-size: 2rem;
      line-height: 28px; } }

.modal-body {
  display: block;
  width: 100%;
  font-size: 1.4rem;
  padding: 30px 20px;
  height: auto;
  text-align: left; }
  @media screen and (min-width: 768px) {
    .modal-body {
      font-size: 1.6rem; } }
  @media screen and (min-width: 992px) {
    .modal-body {
      padding: 30px 50px 50px; } }
  .modal-body .row {
    word-break: break-word;
    overflow-wrap: break-word; }
  .modal-body p {
    margin: 0 auto 20px; }
  .modal-body label {
    text-align: left; }
  .modal-body__content {
    min-height: auto; }
    @media screen and (min-width: 768px) {
      .modal-body__content {
        min-height: auto; } }
    @media screen and (min-width: 992px) {
      .modal-body__content {
        min-height: auto; } }
    .modal-body__content .loading {
      text-align: center; }
    @media screen and (min-width: 992px) {
      .modal-body__content .auto-logout {
        padding-top: 20px; } }
    @media screen and (min-width: 992px) {
      .modal-body__content .auto-logout .progress-circle .CircularProgressbar {
        width: 60px; } }
    @media screen and (min-width: 992px) {
      .modal-body__content .auto-logout .progress-circle .seconds {
        width: 60px;
        top: 9px; } }
    @media screen and (min-width: 992px) {
      .modal-body__content .auto-logout .progress-circle {
        float: none;
        margin: 0 auto; } }

form .otp-number,
form .pin-number {
  width: 35px;
  height: 35px;
  max-width: 50px;
  margin: 0 9px;
  display: inline-block;
  text-align: center;
  border-radius: 0;
  font-size: 1.6rem;
  border: 1px solid #7A7A7A;
  box-shadow: none;
  padding: 6px;
  color: #555; }
  form .otp-number:focus,
  form .pin-number:focus {
    border-color: #FC0;
    border-width: 2px;
    box-shadow: none; }
  form .otp-number:first-child,
  form .pin-number:first-child {
    margin-left: 0; }
  @media screen and (min-width: 768px) {
    form .otp-number,
    form .pin-number {
      width: 48px;
      height: 48px; } }

@media screen and (min-width: 768px) {
  form .form-control {
    padding: 0 10px; } }

form .form-group.has-error .with-errors {
  text-align: left;
  padding: 2px 10px 0; }
  @media screen and (min-width: 768px) {
    form .form-group.has-error .with-errors {
      padding: 2px 12px 0; } }

.modal-buttons {
  margin-top: 20px;
  text-align: center; }
  @media screen and (min-width: 768px) {
    .modal-buttons {
      margin-top: 40px; } }
  @media screen and (min-width: 992px) {
    .modal-buttons {
      text-align: left; } }
  .modal-buttons .btn {
    width: 210px; }
    .modal-buttons .btn:last-child {
      margin-bottom: 0;
      margin-right: 0; }
    @media screen and (min-width: 768px) {
      .modal-buttons .btn {
        width: 320px; } }
    @media screen and (min-width: 992px) {
      .modal-buttons .btn {
        width: 210px; } }
  @media screen and (min-width: 768px) {
    .modal-buttons .btn + .btn {
      margin-top: 30px; } }
  @media screen and (min-width: 992px) {
    .modal-buttons .btn + .btn {
      margin: 0 0 0 30px; } }
  .modal-buttons--custom {
    position: relative;
    height: 100px; }
    .modal-buttons--custom .btn {
      margin-left: -105px;
      left: 50%; }
      @media screen and (min-width: 768px) {
        .modal-buttons--custom .btn {
          width: 320px;
          margin-left: -160px; } }
      @media screen and (min-width: 992px) {
        .modal-buttons--custom .btn {
          width: 200px;
          left: 0;
          margin-left: 0; } }
      .modal-buttons--custom .btn:first-child {
        position: absolute;
        bottom: 0;
        margin-bottom: 0; }
      .modal-buttons--custom .btn:last-child {
        position: absolute;
        top: 0; }
    @media screen and (min-width: 768px) {
      .modal-buttons--custom {
        height: 120px; } }
  @media screen and (min-width: 768px) and (min-width: 768px) {
    .modal-buttons--custom .btn + .btn {
      margin-top: 0; } }
  @media screen and (min-width: 768px) and (min-width: 992px) {
    .modal-buttons--custom .btn + .btn {
      margin: 0 0 0 30px; } }
    @media screen and (min-width: 992px) {
      .modal-buttons--custom {
        height: auto;
        position: static; }
        .modal-buttons--custom .btn:first-child {
          position: static; }
        .modal-buttons--custom .btn:last-child {
          position: static; } }

.animation-show-effect.fade {
  transition: none; }
  .animation-show-effect.fade .modal-dialog {
    transform: translateY(-25%);
    transition: transform .3s ease-out; }
  .animation-show-effect.fade.in .modal-dialog {
    transform: none; }

.modal-backdrop.modal-backdrop--disable {
  transition: none;
  transform: none; }

@media screen and (min-width: 768px) {
  .modal-template .modal-dialog {
    padding: 0;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto; } }

@media screen and (min-width: 768px) {
  .modal-template .modal-header {
    text-align: left;
    padding-left: 40px; }
    .modal-template .modal-header span {
      display: inline-block;
      width: auto; }
    .modal-template .modal-header .img {
      margin-left: 0; } }

.modal-template .modal-title {
  font-size: 14px; }
  @media screen and (min-width: 768px) {
    .modal-template .modal-title {
      font-size: 20px; } }

.modal-template .modal-body {
  padding: 20px; }
  @media screen and (min-width: 768px) {
    .modal-template .modal-body {
      padding: 35px 50px; } }

.modal.shake .otp {
  animation: shake 0.62s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px; }

.otpModal .modal-title {
  line-height: 25px; }
  @media screen and (min-width: 992px) {
    .otpModal .modal-title {
      line-height: 65px; } }

.progress-circle {
  position: relative;
  width: 68px;
  float: none;
  margin: 0 auto; }
  @media screen and (min-width: 992px) {
    .progress-circle {
      height: 70px;
      float: left;
      margin-right: 20px; } }
  .progress-circle .CircularProgressbar {
    width: 70px; }
    .progress-circle .CircularProgressbar .CircularProgressbar-path {
      stroke: #FFF;
      stroke-linecap: butt;
      transition: stroke-dashoffset .5s ease 0s; }
    .progress-circle .CircularProgressbar .CircularProgressbar-trail {
      stroke: #3EB5E5; }
  .progress-circle .seconds {
    width: 70px;
    position: absolute;
    z-index: 100;
    text-align: center;
    top: 15px;
    left: 0;
    font-size: 3rem;
    line-height: 3rem; }
    .progress-circle .seconds span {
      display: block;
      font-size: .8rem;
      margin-top: -10px; }

#registerBrowser .modal-dialog .modal-content {
  height: auto; }
  #registerBrowser .modal-dialog .modal-content .modal-header {
    float: none;
    width: 100%;
    border-right: none;
    padding-top: 32px;
    padding-bottom: 26px;
    border-bottom: 17px solid #FC0;
    height: auto; }
    #registerBrowser .modal-dialog .modal-content .modal-header img {
      margin-bottom: 30px; }
    #registerBrowser .modal-dialog .modal-content .modal-header h1 {
      font-size: 3.2rem;
      margin: 0; }
  #registerBrowser .modal-dialog .modal-content .modal-body {
    padding-top: 35px;
    text-align: center;
    float: none;
    width: 100%; }
    #registerBrowser .modal-dialog .modal-content .modal-body .device {
      overflow: hidden;
      padding-bottom: 50px; }
      #registerBrowser .modal-dialog .modal-content .modal-body .device p {
        font-size: 2rem;
        margin-bottom: 20px;
        max-width: none; }
      #registerBrowser .modal-dialog .modal-content .modal-body .device input {
        height: 48px;
        line-height: 46px;
        width: 252px;
        border: 1px solid #7A7A7A;
        font-size: 20px;
        padding: 0 10px;
        text-align: center; }
    #registerBrowser .modal-dialog .modal-content .modal-body p {
      max-width: none; }

#registerBrowser a {
  color: inherit; }

@media screen and (max-width: 767px) {
  #registerBrowser .modal-dialog .modal-content .modal-header {
    border-bottom-width: 10px;
    padding-bottom: 20px;
    padding-top: 20px; }
    #registerBrowser .modal-dialog .modal-content .modal-header img {
      margin-bottom: 20px; }
    #registerBrowser .modal-dialog .modal-content .modal-header h1 {
      font-size: 1.8rem; }
  #registerBrowser .modal-dialog .modal-content .modal-body {
    padding-top: 20px;
    padding-left: 15px; }
    #registerBrowser .modal-dialog .modal-content .modal-body .device {
      padding-bottom: 35px; }
      #registerBrowser .modal-dialog .modal-content .modal-body .device input {
        width: 85%; }
      #registerBrowser .modal-dialog .modal-content .modal-body .device p {
        font-size: 1.4rem; }
    #registerBrowser .modal-dialog .modal-content .modal-body p {
      font-size: 1rem; } }

@media screen and (min-width: 992px) {
  #registerBrowser .modal-dialog {
    width: 945px; } }

.box .box-header-accounts {
  padding: 8px 12px;
  display: flex; }
  @media screen and (min-width: 768px) {
    .box .box-header-accounts {
      padding: 21px 20px; } }
  .box .box-header-accounts h3 {
    flex: 1 1;
    padding-left: 0;
    padding-top: 4px;
    padding-bottom: 4px; }
  .box .box-header-accounts .add-icon {
    width: 24px;
    height: 24px;
    margin-left: 10px; }

.box .featured-list-item__link-icon svg {
  width: 32px;
  height: 32px;
  margin-right: 50px; }
  @media screen and (min-width: 768px) {
    .box .featured-list-item__link-icon svg {
      width: 48px;
      height: 48px; } }

@media screen and (min-width: 768px) {
  .box-content-accounts {
    min-height: 94px; } }

.box-content-accounts__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 86px; }

.box-content-accounts__icon {
  width: 14%; }
  @media screen and (min-width: 768px) {
    .box-content-accounts__icon {
      width: 10%; } }
  .box-content-accounts__icon img {
    width: 32px;
    height: 32px;
    display: block; }
    @media screen and (min-width: 768px) {
      .box-content-accounts__icon img {
        width: 48px;
        height: 48px; } }

.box-content-accounts__name {
  width: 76%;
  padding-left: 0; }
  @media screen and (min-width: 768px) {
    .box-content-accounts__name {
      width: 50%;
      padding-left: 28px; } }

.box-content-accounts__money {
  width: 30%; }

.box-content-accounts__arrow {
  width: 10%;
  text-align: right; }

.box-content-accounts__messages {
  padding: 10px; }
  @media screen and (min-width: 768px) {
    .box-content-accounts__messages {
      padding: 10px; } }

.box-content-accounts ul.border-bottom li {
  border-bottom: 1px solid #D1D1D1; }

.box-content-accounts ul li a {
  color: #107BBF; }
  .box-content-accounts ul li a label {
    cursor: pointer; }

.box-content-accounts ul li label {
  font-family: "HelveticaNeue-Bold";
  font-weight: normal; }

.box-content-accounts ul li.money-field {
  display: none; }
  @media screen and (min-width: 768px) {
    .box-content-accounts ul li.money-field {
      display: block; } }

.box-content-accounts .row-link {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media screen and (min-width: 768px) {
    .box-content-accounts .row-link {
      padding: 23px 20px; } }
  .box-content-accounts .row-link .icon-wrap__icon {
    margin: 0 auto; }

.transaction-info__img.account-icon {
  background-image: url("../assets/images/icon-account.svg"); }

.media-money-transfer .media-left {
  padding-right: 15px; }
  .media-money-transfer .media-left .icon {
    display: block;
    width: 48px;
    height: 48px;
    background: 50% no-repeat;
    background-size: contain; }

.media-money-transfer .media-body .content {
  font-family: "HelveticaNeue-Light", "sans-serif";
  font-size: 16px;
  font-weight: 700; }
